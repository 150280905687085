import React from 'react'

const Gdpr = () => {
  return (
    <div className='flex flex-col justify-center items-center bg-slate-200 px-20'>
        <div className='j-font2 text-4xl p-10'>Zásady ochrany osobních údajů</div>
        <div className='j-font3 text-xl py-10'>
            <p>Společnost Domapro-servis s.r.o. provádí zpracování Vašich osobních údajů, neboť je to nezbytné pro splnění smlouvy s Vámi ohledně prodeje zboží (nebo pro provedení opatření přijatých před uzavřením takové smlouvy), a dále provádí zpracování Vašich osobních údajů, které je nezbytné pro plnění veřejnoprávních povinností této společnosti.</p>
            <h1 className='py-4'>1. TOTOŽNOST A KONTAKTNÍ ÚDAJE SPRÁVCE</h1>
            <p>1.1. Správcem Vašich osobních údajů je obchodní společnost Domapro-servis s.r.o., se sídlem Michelská 966/74, Michle, 141 00 Praha 4. Identifikační číslo: 21218471, z obchodního rejstříku, vedeného Městským soudem v Praze oddíl C, vložka 398397(dále jen “správce”) 1.2. Kontaktní údaje správce jsou následující: adresa pro doručování Michelská 966/74, Michle, 141 00 Praha 4. Adresa elektronické pošty Domaproservis@gmail.com</p>
            <h1 className='py-4'>2. PRÁVNÍ ZÁKLAD ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</h1>
            <p>2.1. Právním základem zpracování Vašich osobních údajů je skutečnost, že toto zpracování je nezbytné pro:
2.1.1. splnění smlouvy mezi Vámi a správcem nebo pro provedení opatření správcem před uzavřením takové smlouvy ve smyslu čl. 6 odst. 1 písm. b) Nařízení Evropského parlamentu a Rady 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dále jen „nařízení“);
2.1.2. splnění právních povinností, které se na správce vztahují, ve smyslu čl. 6 odst. 1 písm. c) nařízení, a to konkrétně zejména splnění povinností uložených správci obecně závaznými právními předpisy, zejména zákonem č. 235/2004 Sb., o dani z přidané hodnoty, ve znění pozdějších předpisů, zákonem č. 586/1992 Sb., o daních z příjmů, ve znění pozdějších předpisů, a zákonem č. 563/1991 Sb., o 2 / 3 účetnictví, ve znění pozdějších předpisů.
</p>
            <h1 className='py-4'>3. ÚČEL ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</h1>
            <p>3.1. Účelem zpracování Vašich osobních údajů je plnění smlouvy mezi Vámi a správcem, včetně doručení zboží a řešení práv z odpovědnosti za vady, či provedení opatření správcem před uzavřením takové smlouvy a dále plnění souvisejících veřejnoprávních povinností správcem.
3.2. Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu čl. 22 nařízení.
</p>
            <h1 className='py-4'>4. DOBA ULOŽENÍ OSOBNÍCH ÚDAJŮ</h1>
            <p>4.1. Vaše osobní údaje budou zpracovávány po dobu trvání účinků práv a povinností ze smlouvy a dále po dobu nutnou pro účely archivování podle příslušných obecně závazných právních předpisů, nejdéle však po dobu stanovenou obecně závaznými právními předpisy.</p>
            <h1 className='py-4'>5. DALŠÍ PŘÍJEMCI OSOBNÍCH ÚDAJŮ</h1>
            <p>5.1. Dalšími příjemci Vašich osobních údajů budou zasílatelské společnosti a jiné osoby podílející se na dodání zboží či realizaci plateb na základě kupní smlouvy, a osoby zajišťující pro správce technické služby související s provozem e-shopu, včetně provozu software a ukládání dat. Dalšími příjemci Vašich osobních údajů budou Domapro-servis s.r.o.
5.2. Příjemci Vašich osobních údajů zpracovávaných za účelem plnění povinností vyplývajících z právních předpisů můžou být dále orgány finanční správy či jiné příslušné úřady v případech, kdy tak správci ukládají obecně závazné právní předpisy.
5.3. Správce nemá v úmyslu předat Vaše osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci.
</p>
            <h1 className='py-4'>6. PRÁVA SUBJEKTU ÚDAJŮ</h1>
            <p>6.1. Za podmínek stanovených v nařízení máte právo požadovat od správce přístup k Vašim osobním údajům, právo na opravu nebo výmaz Vašich osobních údajů, 3 / 3 popřípadě omezení jejich zpracování, právo vznést námitku proti zpracování Vašich osobních údajů a dále právo na přenositelnost Vašich osobních údajů.
6.2. Pokud byste se domníval(a), že zpracováním Vašich osobních údajů bylo porušeno či je porušováno nařízení, máte mimo jiné právo podat stížnost u dozorového úřadu.
6.3. Nemáte povinnost osobní údaje poskytnout. Poskytnutí Vašich osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy a bez poskytnutí Vašich osobních údajů není možné smlouvu uzavřít či ji ze strany správce splnit.

Tyto podmínky nabývají účinnosti dnem 16.2.2024
</p>
        </div>
    </div>
  )
}

export default Gdpr