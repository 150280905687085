import React from 'react';
import plumber_1 from '../assets/plumber_1.jpg';
import plumber_2 from '../assets/plumber_2.jpg';
import plumber_3 from '../assets/plumber_3.jpg';
import plumber_4 from '../assets/plumber_4.jpg';
import '../index.css';

const Skills = () => {
  return (
    <section id='Co umíme'>
      <div className='flex flex-col justify-center items-center bg-zinc-100 m-0'>
        <p className='j-font2 text-6xl pb-2 md:pb-6 pt-10 px-6 m-0'>Poskytujeme tyto služby</p>
        <div className='flex flex-col md:flex md:flex-row pt-12 px-6 bg-zinc-100'>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-10'>
            <img src={plumber_4} alt='' className='rounded-xl w-[350px] h-[250px] p-1 '/>
            <h1 className='j-font2 text-4xl py-6 text-center'>Čištění odpadů</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl py-0.5'><span className="bullet">&#8226;</span>Ucpaný dřez, toaleta, umyvadlo, vana, stoupačky a další.</li>
              <li className='j-font3 text-2xl py-0.5'><span className="bullet">&#8226;</span>čištění a frézování všech průměrů kanalizačního potrubí</li>
            </ul>
          </div>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-10'>
            <img src={plumber_3} alt='' className='rounded-xl w-[350px] h-[250px] p-1 '/>
            <h1 className='j-font2 text-4xl py-6'>Akutní opravy</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Prasklé vodovodní/kanalizační potrubí</li>  
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Nálezy průsaků</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Protékající toalety</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Porušené a protékající sifony</li>
            </ul>
          </div>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-10'>
            <img src={plumber_2} alt='' className='rounded-xl w-[350px] h-[250px] p-1'/>
            <h1 className='j-font2 text-4xl py-6 text-center'>Montáže, výměny, opravy</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Vodovodní baterie</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Sifony</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Toalety, bidety</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Dřezy, umyvadla</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Vany a sprchové kouty</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Rozvody vody a kanalizace</li>
            </ul>
          </div>
        </div>
        <div className='flex flex-col md:flex md:flex-row py-12 px-6'>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-2'>
            <img src={plumber_1} alt='' className='rounded-xl w-[350px] h-[250px] p-1'/>
            <h1 className='j-font2 text-4xl py-6 text-center'>Kompletní rozvody</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Vodovodní potrubí “na klíč”</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>Kanalizační potrubí “na klíč”</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span>kompletní i částečné rekonstrukce sítí</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
