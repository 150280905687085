import React from 'react'
import '../index.css'
import Callbutton from './Callbutton'
import Navbar from './Navbar.jsx'



const Hero2 = () => {
    return (
        <section className='flex flex-row relative'>
            <div className='absolute top-0 right-0 left-0 z-10'>
                <Navbar />
            </div>
            <div className='w-2/5 bg-slate-300'>
                <div className=' w-5/5 flex flex-col items-start justify-center px-16 md:py-5 bg-black/55 absolute top-0 bottom-0 md:bottom-40 md:top-40'>
                    <div className="text-white mt-3 md:mt-0">
                        <h1 className="j-font text-4xl md:text-6xl">Instalatérské služby v Praze a okolí</h1>
                    </div>
                    <div className="j-font2 text-2xl md:text-3xl mt-7 text-white">
                        <p>Naši technici jsou připravení v jakoukoliv hodinu. Možnost pohotovostního výjezdu (ten samý den)<br>
                        </br> nebo se domluvíme na termínu, který Vám vyhovuje.</p>
                    </div>
                </div>
            </div>
            <div className='bg-img flex-1 bg-slate-800 py-[18rem]'></div>
            <div className='absolute inset-x-16 md:inset-x-28 bottom-11'>
                <Callbutton />
            </div>
        </section>
    )
}

export default Hero2