import React from 'react'

const Prices = () => {
  return (
    <div id='Naše ceny' className="max-w-full mx-auto my-10 px-4 sm:max-w-screen-lg">
      <p className='p-6 j-font text-4xl text-center pb-6'>Ceník</p>
      <div className="overflow-x-auto">
        <table className="w-full divide-y divide-gray-200" style={{ border: '5px solid black', borderCollapse: 'collapse' }}>
          <tbody className="bg-zinc-100 divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                Výjezd a parkovné po Praze:
              </td>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                850,-
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                Výjezd mimo Prahu:
              </td>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                18kč/km
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                Čištění odpadního potrubí: 
              </td>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                862,-/bm
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                Při menších objednávkách cena dohodou
              </td>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                Mimopracovní příplatek (17:00-22:00)
              </td>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                +50%
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                Noční příplatek (22:00-7:00)
              </td>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                +100%
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                Práce o víkendu ( sobota, neděle, státní svátky) 
              </td>
              <td className="px-3 py-2 j-font3 text-lg" style={{ border: '5px solid black' }}>
                +100%
              </td>
            </tr>
            
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Prices
