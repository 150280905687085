import React from 'react'
import payments from '../assets/payments.svg'
import pohotovost from '../assets/pohotovost.svg'
import notifikace from '../assets/notifikace.svg'
import team from '../assets/team.svg'

const Aboutus = () => {
  return (
    <div id='O nás' className='flex pt-11 flex-col px-5 pb-20'>
        <div className='flex flex-col md:flex md:flex-row'>
            <div className='px-2 flex flex-col items-center'>
                <img src={team} alt='' className='w-[160px] h-[160px] pb-4'/>
                <div className='pb-4 text-center j-font2 text-2xl'>Profesionální tým</div>
                <div className='j-font3 text-xl'>Náš kolektiv tvoří odborníci s bohatými zkušenostmi napříč všemi řemeslnými obory. Od zedníka, či štukatéra, přes Instalatéra, elektrikáře nebo dokonce zámečníka. Najdete u nás vše, co může vaše domácnost potřebovat.</div>
            </div>
            <div className='px-2 flex flex-col items-center'>
                <img src={payments} alt='' className='w-[160px] h-[160px] pb-4'/>
                <div className='pb-4 text-center j-font2 text-2xl'>Dostupné ceny</div>
                <div className='j-font3 text-xl'>Udržujeme konkurenceschopné ceny. V dnešní době zdražování a inflace se snažíme vyjít našim zákazníkům vstříc, aby se návštěva řemeslníka nestala noční můrou pro jejich peněženky.</div>
            </div>
            <div className='px-2 flex flex-col items-center'>
                <img src={pohotovost} alt='' className='w-[160px] h-[160px] pb-4'/>
                <div className='pb-4 text-center j-font2 text-2xl'>Nonstop pohotovost</div>
                <div className='j-font3 text-xl'>Jsme tu pro Vás 24 hodin denně, 7 dní v týdnu. Ať už potřebujete vyřešit akutní problém brzy ráno, večer po práci nebo pozdě v noci, zavolejte a naši technici u vás mohou být už do 30ti minut.</div>
            </div>
            <div className='px-2 flex flex-col items-center'>
                <img src={notifikace} alt='' className='w-[160px] h-[160px] pb-4'/>
                <div className='pb-4 text-center j-font2 text-2xl'>Pozitivní hodnocení</div>
                <div className='j-font3 text-xl'>Jsme na trhu už nějaký ten pátek a dobře víme, že spokojený zákazník je naše nejlepší vizitka. Garantujeme profesionální přístup, příjemné jednání a ochotu pomoci vám vyřešit jakýkoliv problém.</div>
            </div>
        </div>
    </div>
  )
}

export default Aboutus