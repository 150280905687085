import React from "react";
import Home from "./pages/Home.jsx";

import { Routes, Route } from "react-router-dom";
import Zasady from "./pages/Zasady.jsx";


function App() {
  return (
      <>
        <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/privacy-policy" element={<Zasady />} />
        </Routes>
      </>
  );
}

export default App;
