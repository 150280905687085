import React from 'react';
import  logot from "../assets/nobg11.png";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='py-2 bg-[#d0cdca] flex flex-col lg:flex-row lg:justify-between'>
      <div className='flex flex-col pl-4 lg:pl-14 pt-4 lg:pt-8'>
        <p className='j-font3 text-xl mb-2 lg:mb-0'>e-mail: domaproservis@gmail.com</p>
        <p className='j-font3 text-xl mb-2 lg:mb-0'>telefon: 608 027 522</p>
        <p className='j-font3 text-xl mb-2 lg:mb-0'>Adresa: Michalská 966/74, Praha 4</p>
        <Link to="/privacy-policy"><p className='j-font3 text-xl hover:text-blue-600'>Zásady ochrany osobních údajů</p></Link>
      </div>
      <div className='flex justify-center lg:justify-end px-4 lg:px-14 text-white'>
        <Link to='/' smooth><img src={logot} alt='' className='w-[380px] h-[135px]'/></Link>
      </div>
    </div>
  );
};

export default Footer;
