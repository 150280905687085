export const navLinks = [
    {
      id: "O nás",
      title: "O nás",
    },
    {
      id: "Co umíme",
      title: "Co umíme",
    },
    {
      id: "Naše ceny",
      title: "Naše ceny",
    },
  ];