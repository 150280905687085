import React from 'react'
import Callbutton from './Callbutton'

const Calldiv = () => {
  return (
    <div className='bg-img-second flex flex-col justify-center items-center px-11 py-24 bg-[#1A3364]'>
      <p className='j-font2 pb-8 text-white text-4xl'>Volejte 24 hodin denně 7 dní v týdnu.</p>
      <div>
        <Callbutton />
      </div>
      
    </div>
  )
}

export default Calldiv