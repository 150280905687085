import React from "react";
import Navbar2 from "../components/Navbar2";
import Gdpr from '../components/Gdpr'

import Footer from "../components/Footer";



function Zasady() {
  return (
      <div>
        <Navbar2 />
        <Gdpr />
        <Footer />
      </div>
  );
}

export default Zasady;
