import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo.png'

const Navbar2 = () => {
  return (
    <nav className="bg-[#312c26] w-full flex justify-between items-center navbar px-10">
        <Link to="/"><img src={logo} alt="hoobank" className="w-[135px] h-[135px]" /></Link>
        <ul>
            <li className='text-white text-xl j-font3 hover:text-blue-600'><Link to="/" >Hlavní stránka</Link></li>
        </ul>
    </nav>
  )
}

export default Navbar2