import React from 'react';
import call from '../assets/call_black.png'; // Import your logo image
import '../index.css';

const Callbutton = () => {
  return (
    <button className='button-second j-font2 flex flex-row justify-center items-center py-2 px-6 md:py-4 md:px-11'>
      <a href="tel:+420 608 027 522" className="flex items-center justify-center">
      <img src={ call } alt="" className='w-7 h-7'></img>
      <p className='pl-2'>608 027 522</p>
      </a>
    </button>
  );
}

export default Callbutton;
